/* News Article View */
// 1. News Article
// 2. Page layout padding/structure
// 3. Tags

// clear fix
.newsItemColumn{
  @extend %clearfix;
}
// 1. News Article
.newsItemElement {
  .titleText{
    margin-bottom: rem(15);
    font-family: $fontOne;
    font-weight: 500;
    @include font-smoothing;
    color: $primary-color;
  }
  .authorDetails {
    font-size: em(12);
    margin:15px 0;
  }
  .teaserText {
    font-family: $fontOne;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: $bodyCopyColorOne;
  }
  .imageOriginal {
    display: block;
    width: 100%;
    border: $borderWidth solid $borderColor;
    margin-bottom: 1rem;
  }
  .newsArticleThumbnail{
    width: 100%;
    max-width: 100%;
  }
}

// 2. Page layout padding/structure
.newsPage .yieldPageContent{
  padding-top: $bootstrap-padding-md * 2px !important;
}
@media only screen and (max-width: 768px){
  .newsPage .yieldPageContent{
    padding-top: $bootstrap-padding-sm * 2px !important;
  }
}

// 3. Tags
.newsTags {
  font-size: .75rem;
}


.layoutContainerAdmin{
  .newsItemElement{
    padding-left: 10px;
    padding-right: 10px;
  }
}
.newsContentNode{
  .newsItemElement .newsArticleThumbnail[src*="_thumb."]{
    display: none;
  }
}
.newsItemColumn .newsItemElement{
  display: none;
}
