// 1. From Theme
// 2. From Breakpoints
// 3. Default Styles

// 1. From Theme
.pageEl {
  .tabbedElement {
    ul.contentTabs {
      border: none;

      li {
        span {
          a {
            display: block;
          }
        }
      }
    }

    .tabContainer {
      .layoutContainer{
        margin: 0 (-$page-element-margin);
      }
      .current {
        // There is an inline width set here.
        // Need to uddate tabElement to remove.
        width: 100% !important;
      }
    }
  }
}

// Edit Mode Fixes
// .edit_mode {
//   .contentTabs.rapidTabsConstruction {
//     li span a {
//       color: #666;
//       background: transparent;
//       border-right: none;
//     }
//
//     .selected span a {
//       background: #fccd22;
//       color: #333;
//     }
//   }
// }


// 2. From Breakpoints
// @media only screen and (max-width: 1024px) {
//   .tabbedElement {
//     ul.contentTabs {
//       li {
//         width: 33%;
//         padding: 2px;
//
//         span {
//           a {
//             width: 100%;
//             display: block;
//             text-align: center;
//           }
//         }
//       }
//     }
//   }
// }
//
// @media only screen and (max-width: 767px) {
//   .tabbedElement {
//     ul.contentTabs li {
//       width: 50%;
//       padding: 2px;
//
//       span a {
//         width: 100%;
//         display: block;
//         text-align: center;
//       }
//     }
//   }
// }
//

// 3. Default Styles
/* Content Tabbed Navigation
-------------------------------------------------------------------- */
@mixin tabs(){
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: inset -3px 0px 0px 0px $primary-color, inset -6px 0px 0px 0px #FFF, inset 3px 0px 0px 0px $primary-color, inset 6px 0px 0px 0px #FFF;
  background-color: $secondary-color;
  padding: 0 6px;
  li{
    display: block;
    flex: 1 0 auto;
    background-color: transparent;
    transition: background-color $transition-linear, box-shadow $transition-linear;
    span{
      display: block;
      a{
        display: block;
        padding: 11px;
        width: 100%;
        text-align: center;
        color: $white;
        transition: color $transition-linear;
        font-size: 16px;
        line-height: 1.125;
        font-weight: 600;
        font-family: $primary-font-bold;
        text-transform: uppercase;
        @include font-smoothing;

      }
    }
    &:hover,
    &.selected{
      background-color: $secondary-color-dk;
      a{
        color: $white;
      }
    }
  }
}


.contentTabs {
  @include tabs;
  list-style-type: none;
  width: 100%;
  .pageEl > &{
    margin-top: $page-element-margin;
    margin-bottom: $page-element-margin;
  }
  @extend %clearfix;
  &.rapidTabsConstruction a.tabLink {
    padding-top: 4px;
    padding-left: 8px;
    display: inline-block;
    background: none;
  }
}

.pageElementAdmin .rosterPlayerStatElement .contentTabs { margin: 0; }

.dataTable .disabled {
  color: #000;
  text-decoration: none;

  &:hover { text-decoration: underline; }
}
.tourney-standings-tab {
   text-align: center;
   padding-top: 5px;
   cursor: pointer;
 }

 .standings-tab-padding {
   padding-left: 25px
 }

/* Loading Tab Animation
-------------------------------------------------------------------- */
#contentTabsLoader {
  text-align: center;
  clear: both;
  padding: 25px;
}


/* Rapid Tabs Construction
-------------------------------------------------------------------- */
.rapidTabsConstruction {
  $construction-yellow: #fccd22;
  $construction-yellow-hover: darken($construction-yellow, 10%);
  background: #FDDD40;
  border-bottom: none;
  box-shadow: inset 1px 1px 0px 0px $construction-yellow-hover;
  padding: 0;

  li {
    box-shadow: inset -1px -1px 0px 0px $construction-yellow-hover;
    span a {
      color: #333;
      font-size: 14px;
    }
    &:hover,
    &.selected{
      background-color: #fccd22;
      box-shadow: inset -1px -1px 0px 0px $construction-yellow-hover;
      span a{
        color: #333;
      }
    }
  }
}
