#panelTwo{
  padding-top: $page-element-margin;
  .home & {
    padding-top: 0;
  }
}
#siteContainer {
  &, #siteContainer.container-fluid {
    padding: 0 0 15px 0;
    width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: #fff;
  }

  // #displayBodyHeader:not(.displayBodyHeader-image) {
  //   padding: 15px;
  //   font-size: 2.25em;
  //   font-weight: 600;
  //   border-bottom: $borderWidth solid $borderColor;
  //   text-transform: initial;
  // }

  #yieldContent.row { margin: auto; }

  #panelOne { padding: 0; }

  .yieldPageContent { padding: 0; }
}

// body:not(.home) #displayBodyHeader{
//   padding: 15px;
//   min-height: 250px;
//   color: #FFF;
//   font-size: 42px;
//   text-align: center;
//   text-transform: uppercase;
//   display: flex;
//   flex-flow: column;
//   justify-content: center;
//   position: relative;
//   background-image: linear-gradient(to top, rgba($secondary-color, .8), rgba($secondary-color, .8)), url(../images/page_banner.jpg);
//   background-size: cover;
//   background-position: center center;
//   @extend %full-width;
//   span{
//     opacity: 0;
//   }
//   span:first-of-type{
//     font-family: $fontOne;
//     font-size: 32px;
//     font-weight: 400;
//     line-height: .9;
//   }
//   span:last-of-type{
//     font-family: $fontOne;
//     font-size: 60px;
//     font-weight: 600;
//     line-height: .8;
//
//   }
//   span.custom-reveal{
//     opacity: initial;
//     animation-name: fadeIn;
//     animation-duration: .8s;
//     animation-fill-mode: forwards;
//   }
//
// }

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}
