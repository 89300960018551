$scoreboard-height: 100px;
$scoreboard-ribbon-padding: 13px;
$scoreboard-ribbon-height: 125px;
$scoreboard-spacing: 2px;
$scoreboard-card-spacing: 3px;
$scoreboard-card-width: 283px; // 380px; // 154px;
$scoreboard-card-background: #FFF;
$scoreboard-card-background-hover: #FFF;
$scoreboard-nav-color: $primary-color;
$scoreboard-nav-color-hover: $primary-color-dk;
$scoreboard-nav-width: 50px;
$scoreboard-nav-background: $white;
$scoreboard-text-color: $gray-dk;

// 495px

#masterScoreboard {
  background: $gray-md;
  // #siteContainer &{display: none;}
  .scoreboardRibbon {
    max-width: $maxWidth;
    width: 100%;
    margin: 0px auto;
      padding: 15px;
  }
}

.scoreboardRibbon {
  background: $gray-md;
  z-index: 0;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  padding: 15px;
  // height: auto;

  .container{
    z-index: 490;
    overflow: hidden;
    position: relative;
    padding: 0px;
    width: auto;
      margin: 0 10px;

    ul{
      width: 100000px;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      left: 0;
      justify-content: flex-start;
    }
  }

  li.game{
    display: flex;
    width: $scoreboard-card-width;
    @media screen and (max-width: 495px){
      width: calc(100vw - 210px);
    }
    // padding-right: $scoreboard-spacing;
    // &:last-child{
    //   padding-right: 0;
    // }
    + .game{
      > .gameLink{
        margin-left: $scoreboard-card-spacing;
      }
    }
  }

  .gameLink{
    text-decoration: none;
    cursor: pointer;
    display: block;
    background-color: $scoreboard-card-background;
    transition: background-color $transition-standard;
    padding: 20px;
    font-size: 12px; // 15px;
    font-family: $fontOne;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    color: $scoreboard-text-color;
    width: 100%;
    // &:hover{
    //   background-color: $scoreboard-card-background-hover;
    // }
  }
  .game:nth-of-type(even){
    .gameLink{
      background-color: $scoreboard-card-background-hover;
    }
  }

  a > span{
    display: block;
    width: 100%;
  }

  .game{
    .date{
      margin-bottom: 10px;
    }
    .team{
      display: flex;
      flex-flow:row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 10px;

      // + .team{
      //   margin-top: 2px;
      // }
    }
    .name{
    }
    .score{
    }
    .status{
      font-size: 12px;
      line-height: 16px;
    }
  }

  .next_button,
  .previous_button{
    width: $scoreboard-nav-width;
    flex-shrink: 0;
    cursor: pointer;
    z-index: 580;
    color: $scoreboard-nav-color;
    font: 25px FontAwesome;
    line-height: 1;
    text-align: center;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: $scoreboard-nav-background;
    &_disabled{
      cursor: default;
      &:before { color: #999; }
    }
    &::selection,
    &::-moz-selection {
      background: transparent;
    }
    &:hover {
      &:before{
        color: $scoreboard-nav-color-hover;
      }
    }

  }
  .previous_button:before {content: "\f104";}
  .next_button:before {content: "\f105";}
  // .previous_button {border-right: 1px solid $scoreboard-card-background-hover;}
  // .next_button {border-left: 1px solid $scoreboard-card-background-hover;}

    .game .boom{
      .name{
        color: $primary-color;
      }
      .score{
        color: $primary-color;
      }
    }

    .in_progress .status{
      color: $primary-color;
      font-style: italic;
      font-weight: bold;
    }
    .completed .status{
      font-weight: bold;
    }


}
