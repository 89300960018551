// Code that is shared within multiple partials



$sn-pageManager-width: 60px;
%standard-width{
  max-width: $maxWidth;
  width: 100%;
  margin: 0 auto;
}

%full-width{
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .page-manager-visible & {
    width: calc(100vw - #{$sn-pageManager-width});
  }
}

%full-width-image-background{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-width{
  .user_mode &{@extend %full-width;}
  // @extend %full-width;
  // margin-right: 0;
  &.layout-100{
    padding-left: 0;
    .column{
      padding-left: 0;
      padding-right: 0;
      float: none;
    }
  }
}
.full-width-container{
  .user_mode &{@extend %full-width;}
  // @extend %full-width;
  // @extend %full-width-image-background;
  // margin-right: 0;
  .fw-content{
    @extend %standard-width;
    padding: 0 15px;
  }

  // &.oneColumn{
  //   .pageElement{
  //     margin-right: 0;
  //   }
  // }
}
@mixin no-padding{
  .column{
    padding-left: 0;
    padding-right: 0;
  }
}


.background-image{
  .user_mode &{ display: none; }
  .edit_mode &{
    &:before{
      max-width: 300px;
      margin: 0 auto;
      display: block;
      padding: 1em;
      text-align: center;
      content: "This image will be used as the background for this layout container. It will only appear here (as a page element) in edit-mode.";
    }
    img{
      max-width: 300px;
      margin: 0 auto;
      border: 2px solid #FFF;
      box-shadow: 0px 0px 15px rgba(0,0,0,.5);
    }
  }
}
.background-cover-img{
  @extend %full-width-image-background;
}
.compact{
  &.pageEl .pageElement{margin-bottom: 0; margin-top: 0;}
  .pageEl .pageElement{margin-bottom: 0; margin-top: 0;}
  &-top{
    &.pageEl .pageElement{margin-top: 0;}
    .pageEl .pageElement{margin-top: 0;}
  }
  &-bottom{
    &.pageEl .pageElement{margin-bottom: 0;}
    .pageEl .pageElement{margin-bottom: 0;}
  }
}
@media screen and (max-width: 767px){
  .compact:not(.layout-100) .pageEl .pageElement{margin-bottom: 25px;}
  .compact:not(.layout-100) .column:last-of-type .pageEl:last-of-type .pageElement{
    margin-bottom: 0;
  }
}

.layoutContainer{
  &.padding{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &.padding-top{
    padding-top: 40px;
  }
  &.padding-bottom{
    padding-bottom: 40px;
  }
}


.center-align{
  .fw-content, &.layoutContainer{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}



.maintain-ratio .video_aspect_ratio {
  padding-bottom: 0;
}
.textBlockElement > h3:only-child{
  margin-bottom: 0;
}

// .element-header{
//   .textBlockElement > h3 {
//     @include special-header;
//   }
// }


.background-blue{
  background-color: $secondary-color;
  background-image: url(../images/blue-rink.jpg);
  background-size: cover;
  background-position: center;
  .fw-content{
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pageEl{
    background: #FFF;
    padding: 0 30px;
  }
}
.background-gray{
  background-color: $gray-lt;
  .fw-content{
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pageEl{
    background: #FFF;
    // padding: 0 30px;
  }
}


.six-column{
  .user_mode &{
    .column{
      display: flex;
      flex-flow: row;
      @media screen and (min-width: 600px) and (max-width: 1015px){
        flex-flow: row wrap;
      }
      @media screen and (max-width: 599px){
        display: block;
      }
    }
    .pageEl{
      width: 100%;
      padding: 2px 1px 0;
      @media screen and (min-width: 600px) and (max-width: 1015px){
        width: (100%/3);
      }
      @media screen and (max-width: 599px){
        padding: 0 0 3px 0;
      }
    }
  }
  .edit_mode &{
    .pageElementAdmin{
      margin: 0 5px;
    }
    .drag{
      width: (100%/6);
      display: inline-block;
      @media screen and (min-width: 600px) and (max-width: 1015px){
        width: (100%/3);
      }
      @media screen and (max-width: 599px){
        width: 100%;
      }
    }
    .addPageElement{
      margin: .25em;
      height: 34px;
      line-height: 30px;
      &:hover{
        margin: .5em;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}

.max-column-four{
  .user_mode &{
    .column{
      display: flex;
      flex-flow: row;
      @media screen and (min-width: 600px) and (max-width: 1015px){
        flex-flow: row wrap;
      }
      @media screen and (max-width: 599px){
        display: block;
      }
    }
    .pageEl{
      width: 100%;
      @media screen and (min-width: 600px) and (max-width: 1015px){
        width: (100%/2);
      }
      @media screen and (max-width: 599px){
        padding: 0 0 3px 0;
      }
    }
  }
  .edit_mode &{
    .pageElementAdmin{
      margin: 0 5px;
    }
    .drag{
      width: (100%/4);
      display: inline-block;
      @media screen and (min-width: 600px) and (max-width: 1015px){
        width: (100%/2);
      }
      @media screen and (max-width: 599px){
        width: 100%;
      }
    }
    .addPageElement{
      margin: .25em;
      height: 34px;
      line-height: 30px;
      &:hover{
        margin: .5em;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}





.site-callouts{
  display: flex;
  flex-flow: row;
  @media screen and (min-width: 600px) and (max-width: 1015px){
    flex-flow: row wrap;
  }
  @media screen and (max-width: 599px){
    display: block;
  }

  .pageEl{
    width: 100%;
    padding: 2px 1px 0;
    @media screen and (min-width: 600px) and (max-width: 1015px){
      width: (100%/3);
    }
    @media screen and (max-width: 599px){
      padding: 0 0 3px 0;
    }
  }
  .pageElement{
    margin: 0;
  }
}
.custom-cta-slides{
  // display: flex;
  // flex-flow: row wrap;
  // justify-content: flex-start;
  .textBlockElement{
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    // @include aspect-ratio(1);
    > h3,
    > .text {
      opacity: 0;
    }
    h3:only-of-type{
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      color: $white;
      font-size: 22px;
      text-align: center !important;
      padding: 0 5px;
      min-height: 2.5em;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      transition: margin-top .25s ease-in-out;
      span{
        @include text-header-inline($secondary-color)
      }

    }
    .text{
      text-align: center;
      display: flex;
      flex-flow: column;
      justify-content: center;
      opacity: 0;
      transition: opacity .2s ease-in-out;
    }
    .slide-content{
      background-color: rgba($primary-color, 0.9);
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0;
      // min-height: 53px; // set by javascript
      transition: height .25s ease-in-out;
      display: flex;
      flex-flow: column;
      //justify-content: center;
      text-align: center;
      .text{
        color: #FFF;
        p{
          margin-bottom: 5px;
        }
        a,
        a:hover{
          color: #FFF;
          border: 0;
        }
      }
    }
    // &:hover{
    //   .slide-content{
    //     height: 100%;
    //     justify-content: center;
    //   }
    //   .text{
    //     opacity: 1;
    //   }
    // }
    @media screen and (max-width: 599px){
      // min-height: 55px;
      .slide-content{
        position: relative;
      }
      &.hover{
        .slide-content{
          height: 100%;
        }
        .text{
          opacity: 1;
        }
      }
    }
    @media screen and (min-width: 600px){
      @include aspect-ratio(1);
      &:hover{
        .slide-content{
          height: 100%;
        }
        .text{
          opacity: 1;
        }
        h3:only-of-type {
          margin-top: 30%;
        }
      }
    }
    .leftTextImage,
    .rightTextImage{
      display: none;
    }
  }
}


.user_mode{
  .theme-main-nav .theme-nav-item.home,
  .theme-nav-forward.page_node_2545708{
    display: none !important;
  }
}


.custom-ad{
  background-color: $gray-md;
  .adbox{
    padding: 0 !important;
  }
  .edit_mode & {
    width: 100%;
  }
}
