/* News Aggregator */
// MAKE LIQUID!!!!
// 1. Mixins
// 2. Global Styles
// 3. Expanded View
// 4. Condensed View
// 5. Comment Count and Read More

$news-entry-padding-vert: 15px;
$news-entry-padding-horz: 15px;
$news-entry-spacing: 0px;
$news-entry-spacing-top: 15px;

$news-article-title-size: 14px;
$news-article-title-color: $secondary-color;
$news-article-title-color-hover: $primary-color;
$news-article-line-color: $gray-md;

// 1. Mixins (Remove when liquid)
@mixin news-entry{
  &:first-of-type{
    margin-top: $news-entry-spacing-top;
    padding-top: 0;
    border-top: none;
  }
  border-top: 1px solid $news-article-line-color;
  padding: $news-entry-padding-vert 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%; //
}
@mixin news-entry-image(){
  @include aspect-ratio(83, 66);
  width: 100%;
  flex-basis: 100%;
  max-width: 83px; // 140px;
  max-height: 66px; // 105px;
  display: inline-block;
  margin: 0 $news-entry-padding-horz 0 0 ;
  opacity: 1;
  transition: opacity $transition-standard;
  position: relative;
  &:hover{opacity: .8;}
}
@mixin news-entry-content{
  width: 100%;
  flex-basis: 100%;
  align-self: center;
}

@mixin news-entry-header{
  font-family: $fontOne;
  font-size: $news-article-title-size;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.4;
  > a{
    color: $news-article-title-color;
    font-weight: 500;
    transition: color $transition-standard, border $transition-standard;
    &:hover{
      color: $news-article-title-color-hover;
      text-decoration: none;
    }
  }
}
@mixin news-entry-date-author{
  font-family: $fontTwo;
  color: $gray-dk;
  font-size: em(12);
  font-weight: 500;
  line-height: 1.25;
  display: inline;
  margin-right: 4px;
}
@mixin news-entry-teaser{
  font-family: $fontTwo;
  font-size: em(14);
  font-weight: 500;
  line-height: 1.25;
  text-transform: none;
  color: #333;
  margin: .75em 0;
}

// 2. Global styles
.aggHeader{
  @include special-header;
}

.newsAggregatorElement{
  .dateAuthor{
    line-height: 1;
    margin-top: 5px;
  }
  .newsAuthor, .newsDate {
    @include news-entry-date-author;
  }
  .item + .extendedOptions{
    // padding-top: 0;
    // margin-left: 30px;
    // border-top: 1px solid $grey-lt;
    border: none;
    padding-top: 0;
  }

// 3. Expanded Previews
  .expanded{
    @include news-entry;
    img{display: none;}
    .background-cover-img { // _custom/_custom.js generates this class and aranges the dom for this layout (to match the condensed view)
      @include news-entry-image
    }
    .newsItemElement{
      @include news-entry-content;
      h4{ @include news-entry-teaser; }
    }
    .newsItemHeader{
      h2{ @include news-entry-header; }
    }
    .newsText{
      clear: both;
      margin-top: 1em;
      h1, h2, h3, h4, h5, h6 {
        text-transform: none;
        font-weight: 600;
        font-size: 1em;
        letter-spacing: normal;
        margin: 0 0 .5em 0;
        padding: 0;
      }
      ul{
        list-style-type: disc;
      }
    }
  }

// 4. Condensed Previews
  .condensed{
    @include news-entry;
    img{display: none;}
    .background-cover-img {
      @include news-entry-image
    }
    .details,
    > ul{
      list-style-type: none;
      @include news-entry-content
      h4{ @include news-entry-header; }
    }
    .teaser{
      @include news-entry-teaser;
      &:empty{display: none;}
    }
  }
}

// 5. Read more and comments
.commentCount {
  .button-commentCount {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    line-height: 1;
    &:hover{background: none;}
    .button-image-right {
      display: none;
    }
    .button-content {
      margin: 0;
      padding: 0;
      position: relative;
      border: none;
      background: none;
      color: $link-color;
      font-weight: 600;
      top: 2px;
      transition: all $transition-standard;
      &:hover {
        background: none;
        border: none;
        color: $hover-color;
      }
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        font-family: FontAwesome;
        line-height:1;
        content: "\f0e5";
        top: -1px;
        right: 0px;
        padding-right: .25em;
      }
    }
  }
}
.commentsAndMore{
  line-height: 1;
  margin: 5px 0 0 0;
  .readMore{
    display: inline-block;
    margin-right: .5em;
    a{margin: 0;}
    .button-small{
      // font-family: $fontTwo;
      // font-size: 12px;
      // @include font-smoothing;
      // font-weight: 700;
      // background-color: $primary-color;
      // border: 1px solid $blue-lt;
      // color: #FFF;
      // transition: background-color $transition-linear;
      &:hover{
        // background-color: $primary-color-dk;
      }

    }
  }
}

// 7. Responsive
@include page-element-break(320){
  .newsAggregatorElement{
    .expanded,
    .condensed{
      flex-wrap: wrap;
      .background-cover-img{margin: 0 0 $news-entry-padding-vert 0;}
    }
  }
}

// Hoping to get rid of these gross mqs when we figure out our breakpoints and fully implement bootstrap
// Until then, these edge cases will have to be defined outside of the "page-element-break" mixin
@media only screen and (min-width: 769px) and (max-width: 999px){
  .layout-25-25-50,
  .layout-50-25-25{
    .col-md-3, .col-md-6{
      .newsAggregatorElement{
        .expanded,
        .condensed{
          flex-wrap: wrap;
          .background-cover-img{margin: 0 0 $news-entry-padding-vert 0;}
        }
      }
    }
  }
  .layout-25-50-25{
    .col-md-3, .col-md-6{
      .newsAggregatorElement{
        .expanded,
        .condensed{
          flex-wrap: wrap;
          .background-cover-img{margin: 0 0 $news-entry-padding-vert 0;}
        }
      }
    }
  }
}

// USER CLASSES

.title-only{
  // .item{
  //   .details{
  //     .dateAuthor,
  //     .teaser,
  //     .commentsAndMore{
  //       display: none;
  //     }
  //   }
  // }
  .condensed{
    .teaser,
    .dateAuthor,
    .commentsAndMore{
      display: none;
    }
  }
  .expanded{
    .newsItemElement{
      > h4,
      .dateAuthor,
      .commentsAndMore{
        display: none;
      }
    }
  }

}
.no-options{
  .extendedOptions.item{
    display: none;
  }
}


.news-horizontal{
  .newsAggregatorElement{
    display: flex;
    flex-flow: row wrap;
    .item{
      @media screen and (min-width: 768px){
        width: (100%/3);
        border: 0;
      }
      @media screen and (min-width: 571px) and (max-width: 767px){
        width: (100%/2);
        border: 0;
      }
      @media screen and (max-width: 570px){
        width: 100%;
      }
    }
    .expanded{
      .newsItemElement{
        padding-right: 15px;
        > h4,
        .dateAuthor,
        .commentsAndMore{
          display: none;
        }
      }
    }
    .condensed{
      .details,
      > ul{
        padding-right: 15px;
      }
      .teaser,
      .dateAuthor,
      .commentsAndMore{
        display: none;
      }
    }
    .aggHeader,
    .extendedOptions{
      width: 100%;
    }
  }
}
