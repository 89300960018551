// Google Search
#customGoogleSearchBox{
  .theme-search-input,
  .theme-search-clear{
    display: none;
  }
  .theme-search-submit{
    flex-direction: row !important;
    align-items: center;
    span{
      display: inline-block;
      line-height: 1;
    }
    &:after{
      display: inline-block;
      content: "\f002";
      position: relative;
      font-family: FontAwesome;
      line-height: 1;
      margin-left: 5px;
      font-size: .9em;
      //
      // width: 1.2em;
      // height: 1.2em;
      // background-image: url(../images/search_icon.svg);
      // background-size: contain;
      // background-position: center center;
      // background-repeat: no-repeat;
    }
  }
}
#GoogleSearchBoxMobileNav{
  .theme-search-input{
    display: none;
  }
}
#GoogleSearchBoxTopNav{
  display: none !important;
}
