// 4. Site Header

$site-header-height: 364px;
$site-header-height-md: 300px;
$site-header-height-sm: 267px;

// .home #displayBodyHeader { display: none; }
.site-banner-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-size: cover;
  // margin: 0 auto;
  // max-width: $maxWidth;
  min-height: $site-header-height !important;
  position: relative;
  width: 100%;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba($secondary-color, .8), rgba($secondary-color, .8));
    .home &{
      display: none;
    }
  }

  .site-banner-text-wrapper {
    color: #fff;
    font-size: 36px;
    line-height: 1;
    padding: .5em;
    text-align: center;
    width: 100%;
    display: none;

    h2, h3 {
      font-size: 1em;
      line-height: 1.25;
      text-transform: none;
    }

    h1 {
      font-size: 1.5em;
      font-weight: 900;
      line-height: 1;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 699px) {
  .site-banner-wrapper .site-banner-text-wrapper {
    font-size: 4.5vw;

    h1 { font-weight: 700; }
    h2, h3 { display: none; }
  }
}

@media (min-width: 700px) and (max-width: 1000px) {
  .site-banner-wrapper .site-banner-text-wrapper { font-size: 3.6vw; }
}


#displayBodyHeader{
  width: 100%;
  padding: 15px;
  min-height: $site-header-height;
  color: #FFF;
  font-size: 42px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
  background-image: linear-gradient(to top, rgba($secondary-color, .8), rgba($secondary-color, .8)), url(../images/page_banner.jpg);
  background-size: cover;
  background-position: center center;
  span{
    opacity: 0;
  }
  span:first-of-type{
    font-family: $fontOne;
    font-size: 32px;
    font-weight: 400;
    line-height: .9;
  }
  span:last-of-type{
    font-family: $fontOne;
    font-size: 60px;
    font-weight: 600;
    line-height: .8;

  }
  span.custom-reveal{
    opacity: initial;
    animation-name: fadeIn;
    animation-duration: .8s;
    animation-fill-mode: forwards;
  }
}
#siteContainer #displayBodyHeader{
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1015px){
  .site-banner-wrapper {
    min-height: $site-header-height-md !important;
  }
  #displayBodyHeader{
    min-height: $site-header-height-md;
  }
}
@media screen and (max-width: 599px){
  .site-banner-wrapper {
    min-height: $site-header-height-sm !important;
  }
  #displayBodyHeader{
    min-height: $site-header-height-sm;
  }
}

.newsPage{
  .site-banner-wrapper,
  #displayBodyHeader{
    display: none;
  }
}
