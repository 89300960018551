@media screen and (max-width: 768px) {
  .mobile-accordion > {
    .contentTabs{
      display: none;
    }
    .tabContainer .layoutContainer {
      background: transparent none;
    }
    .tabContainer > div {
      display: none !important;
      position: static !important;
      width: 100% !important;
      height: auto !important;
    }
    .tabContainer > div.active {
      display: block !important;
    }
    .tabContainer{
      > li{
        list-style-type: none;
        margin-top: 5px;
        background-color: $secondary-color;
        transition: background-color $transition-linear;
        &:hover,
        &.active{
          background-color: $secondary-color-dk;
        }
        span{
          display: block;
        }
        a{
          display: block;
          padding: 11px;
          width: 100%;
          // text-align: center;
          color: #FFF;
          transition: color $transition-linear;
          font-size: 16px;
          line-height: 1.125;
          font-weight: 600;
          font-family: $primary-font-bold;
          text-transform: uppercase;
          @include font-smoothing;
          &:after{
            content: ' +';
          }
        }
        &.active a:after{
          content: ' -';
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .mobile-accordion > {
    .tabContainer > li,
    .tabContainer > li.active{
      display: none;
    }
    .tabContainer > div {
      display: none!important;
      width: 100% !important;
      &.current{
        display: block!important;
        height: auto !important;
        position: relative !important;
        left: 0!important;
      }
    }
  }
}
