#sn-site-logo {
  background-color: transparent;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all $transition-linear;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  .has-main-nav &{
    width: $site-logo-size-w;
    height: $site-logo-size-h;
    padding: 10px 15px;
    z-index: 1;
  }
  .has-main-nav.nav-fixed & {
    width: $site-logo-size-w-fixed;
    height: $site-logo-size-h-fixed;
    padding: 5px;
  }
  .has-mobile-nav &{
    height: $mobile-nav-bar-height;
    width: $mobile-nav-bar-height;
    padding: 5px;
    transition: none;
  }

  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}
