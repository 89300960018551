.sponsor-slider{
  .column{
    display: flex !important;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    padding: $page-element-margin;
  }
  .pageElement{
    // max-width: 100px;
    // width: 100%;
    // flex-basis: 100px;
    padding: 0 $page-element-margin;
    img{
      width: auto;
      max-height: 125px;
      max-width: 175px;
    }
  }
  // .pageElementAdmin{
  //   img{
  //     max-width: 100px;
  //   }
  // }
  .pageElementAdmin{
    margin: 0 5px;
  }
  .drag{
    width: (100%/6);
    display: inline-block;
    @media screen and (min-width: 600px) and (max-width: 1015px){
      width: (100%/4);
    }
    @media screen and (max-width: 599px){
      width: 100%;
    }
  }
  .addPageElement{
    margin: .25em;
    height: 34px;
    line-height: 30px;
    &:hover{
      margin: .5em;
      height: 30px;
      line-height: 30px;
    }
  }
}
// .sponsor-slider{
// }
