#header-extra{
  padding-right: 15px;
  margin-left: auto;
  .header-links{
    color: $white;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $fontOne;
    font-weight: 600;
    text-decoration: none;
    border: 0;
    @include font-smoothing;;
    transition: color $transition-linear;
    &:hover{
      color: $primary-color;
      text-decoration: none;
      border: 0;
    }
    + .header-links{
      margin-left: 18px;
    }
  }
}
