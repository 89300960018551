// 5. Site Footer
.snFooterContainer {
  width: 100%;
  background-color: $gray-md;
}

#siteFooter{
  width: 100%;
  max-width: $maxWidth;
  margin: 0 auto;
  padding: $page-element-margin;
  font-size: 16px;
  line-height: 1.25;
  font-family: $fontOne;
  font-weight: 600;
  @include font-smoothing;
  color: $secondary-color;
  text-transform: uppercase;
  ul{ list-style: none; }
  #poweredByNGIN{display: none;}

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  .footer-column{
    // width: 25%;
    padding: 0 $page-element-margin;
    margin: $page-element-margin 0;
    box-sizing: content-box;
    img{
      width: 100%;
      max-width: 100%;
    }
    h3{
      font-family: $fontOne;
      font-size: 16px;
      font-weight: 600;
      // line-height: 1.6;
      margin-bottom: 10px;
    }
  }
  .column-1,
  .column-2{
    @media screen and (max-width: 767px){
      display: none;
    }
  }
  .column-3{
    max-width: 230px;
    flex-basis: 230px;
    .partner-wrap{
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      a{
        margin: 0 10px;
      }
      img{
        width: auto;
        max-height: 70px;
      }
    }
  }
  .column-4{
    max-width: 215px;
    flex-basis: 215px;
  }
  .sn-copyright-message{
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }
  .footer-social{
    text-align: center;
    line-height: 1;
    .sn-social-media-icon{
      background-color: $secondary-color;
      transition: background-color $transition-linear;
      &:hover{
        background-color: $primary-color !important;
      }
    }
  }
  .footer-nav-links{
    .disabled,
    #customGoogleSearchBox{
      display: none;
    }

  }
  @media screen and (min-width: 500px) and (max-width: 767px){
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (max-width: 500px){
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .footer-column{
      flex-basis: auto;
    }
  }
}


#footer-extra{

}
