/* News Slideshow Styles */
// 1. Global Styling
// 2. Thumbnails
// 3. Controls
// 4. Buttons
// 5. Aspect Ratio Adjustments
// 6. Accomidations for Small Columns & Mobile Views

// Variables
$control-height: 2.5rem;

// 1. Global Styling
.drag .newsSlideShow { cursor: pointer; }

.newsSlideShow-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: $control-height;
}

.newsSlideShow-article {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - #{$control-height});
  background-color: #fff;

  &.current {
    z-index: 1;
  }

  img { position: absolute; }
}

.newsSlideShow-headline {
  position: absolute;
  bottom: 35px;
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: rgba($secondary-color, .9);
  @include font-smoothing;

  h1 {
    position: relative;
    display: block;
    text-transform: uppercase;

    &:after {
      content: "";
      clear: both;
    }

    a {
      display: block;
      width: 100%;
      color: #fff;
      font-size: 30px;
      line-height: 1.2;
      height: 1.2em;
      text-decoration: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;

      // Sadly, `line-clamp` doesn't work in non-webkit browsers, so we need
      // to restrict it with this hack. `white-space: nowrap` serves as a
      // fallback for other browsers with one line of text instead of two.
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // white-space: normal;

        .col-md-3 & {
          // -webkit-line-clamp: 1;
        }
      }
    }
  }

  p {
    @extend %clearfix;
    display: block;
    margin: 0;
    line-height: 1.1;
    font-size: 20px;
    font-family: $fontOne;
    font-weight: 500;
    text-align: right;

    span {
      display: block;
      // float: left;
      width: auto;
      // max-width: calc(100% - 142px);
      height: 2.25rem;
      padding-right: 10px;
      line-height: 2.25rem;
      color: #fff;
      text-align: left;
      overflow: hidden;
      @include truncate-text;

      &:empty { display:none; }
    }

    a {
      margin: 0;
    }
  }
}

// 2. Thumbnails
.newsSlideShow-thumbnails {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 0;
  width: calc(100% - 5rem); // Subtract width of left/right arrows
  height: $control-height;
  list-style-type: none;
  line-height: 1;
  text-align: center;
  background-color: #fff;
  cursor: default;
  transform: translateX(-50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .col-md-3 & {
    font-size: .5em;
  }

  @media only screen and (max-width: 450px) {
    font-size: .5em;
  }

  li {
    display: inline-block;
  }

  a {
    display: block;
    padding: 4px;

    .col-md-3 & {
      padding: 6px;
    }

    @media only screen and (max-width: 450px) {
      padding: 6px;
    }

    &:before {
      content: '';
      display: block;
      width: .75em;
      height: .75em;
      border-radius: 100%;
      background-color: $link_color;
      transition:
        transform .1s ease-in-out,
        background-color .1s ease-in-out;
    }

    &:hover,
    &.current {
      &:before {
        background-color: $accent_color;
        transform: scale(1.375,1.375);
      }
    }
  }

  img {
    display: none;
    width: 100%;
  }
}

// 3. Controls
.newsSlideShow-controls {}

.newsSlideShow-prev.pill-small-left,
.newsSlideShow-next.pill-small-right {
  position: absolute;
  z-index: 10;
  bottom: 0;
  overflow: hidden;
  width: $control-height;
  height: $control-height;
  padding: 0;
  background-color: #fff;
  // box-shadow: 0 -2px $borderColor;
  font-size: 1em;
  text-align: center;
  color: $link-color;

  &:hover {
    color: $accent_color;
    background-color: #fff;

    &:before {
      transform: scale(1.375);
    }
  }

  &:before {
    line-height: 2.5;
    content: '\f060'; // fa-arrow-right
    display: block;
    color: inherit;
    font-family: 'fontawesome';
    transition:
      transform .1s ease-in-out,
      color .1s ease-in-out;
  }
}

.newsSlideShow-prev.pill-small-left {
  left: 0;
}

.newsSlideShow-next.pill-small-right {
  right: 0;
}

.newsSlideShow-next.pill-small-right:before {
  content: '\f061'; // fa-arrow-left
}

.newsSlideShow-empty {
  text-align: center;

  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}

// 4. Buttons
.newsSlideShow-more.button-small {
  // display: block;
  // position: relative;
  // background-color: $link_color;
  color: $white;
  border-color: $white;
  // font-family: $fontOne;
  // font-size: rem(16);
  // font-style: normal;
  // font-weight: 600;
  // text-transform: uppercase;
  // text-indent: -20px;
  // text-align: center;
  // border: 0;
  // transition: background-color $transition-standard;
  transition: color $transition-standard, background-color $transition-standard;

  &:after{
    content: "\f105"; // Right Arrow
    font-family: FontAwesome;
    // position: absolute;
    // top: 0;
    // left: 50%;
    margin-left: 5px;
    color: $primary-color;
  }

  &:hover{
    border-color: $white;
    background-color: $white;
    color: $secondary-color;
  }
}

.newsSlideShow-stop,
.newsSlideShow-play { display: none !important; }

// 5. Aspect Ratio Adjustments
.aspect_ratio_original,
.aspect_ratio_1_1,
.aspect_ratio_4_3,
.aspect_ratio_16_9,
.aspect_ratio_2_1,
.aspect_ratio_3_1,
.aspect_ratio_4_1,
.aspect_ratio_8_5 { height: initial !important; }

.aspect_ratio_original { @include aspect-ratio(16,9); }
.aspect_ratio_1_1 { @include aspect-ratio(1,1); }
.aspect_ratio_4_3 { @include aspect-ratio(4,3); }
.aspect_ratio_16_9 { @include aspect-ratio(16,9); }
.aspect_ratio_2_1 { @include aspect-ratio(2,1); }
.aspect_ratio_3_1 { @include aspect-ratio(3,1); }
.aspect_ratio_4_1 { @include aspect-ratio(4,1); }
.aspect_ratio_8_5 { @include aspect-ratio(8,5); }

// 6. Accommodations for Small Columns & Mobile Views
@media only screen and (min-width: 767px) {
  .col-md-3,
  .col-md-4 {
    .newsSlideShow-headline {
      h1 a {
        font-size: rem(18);
      }
      p {
        display: none;
      }
    }
  }
  .col-md-3,
  .col-md-4,
  .col-md-6,
  .col-md-8 {
    .aspect_ratio_4_1,
    .aspect_ratio_3_1,
    .aspect_ratio_2_1 {
      .newsSlideShow-headline {
        padding-bottom: 2em;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .newsSlideShow-headline {
    .aspect_ratio_4_1 &,
    .aspect_ratio_3_1 &,
    .aspect_ratio_2_1 & {
        padding-bottom: 2em;
    }
    h1 a {
      font-size: rem(18);
    }
    p {
      display: none;
    }
  }
}
@media only screen and (max-width: 350px) {
  .newsSlideShow-headline {
    p {
      display: none;
    }
  }
}


//
.user_mode .custom-news-slider-header{
  display: none;
}
.custom-news-slider{
  // Shared Styles
  .newsSlideShow-controls{
    display: none;
  }
  .custom-news-slider-header{
    display: block;
    .edit_mode &{ display: none !important; }
  }
  .newsSlideShow-container{
    margin: 0;
    padding: 0;
    // &:before{
    //   padding: 0;
    // }
  }
  .newsSlideShow-thumbnails{
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    transform: none;
    height: auto;
    box-shadow: none;
    display: flex;
    a:before{display: none;}
    li{
      position: relative;
    }
  }
  .newsSlideShow-article{
    // left: 25%;
    // width: 75%;
    height: 100%;
    img{
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
  .custom-thumbnail-image{
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    flex-shrink: 0;
  }
  .custom-thumbnail-heading{
    font-family: $fontOne;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }

  // Mobile View
  @media screen and (max-width: 1014px){
    .custom-thumbnail-link{
      padding: 0;
    }
    .custom-news-slider-header,
    .custom-thumbnail-heading{
      display: none;
    }
    .newsSlideShow-thumbnails{
      width: 100%;
      flex-flow: row;
      justify-content: flex-start;
      li{
        padding: 2px 2px 2px 0;
        width: (100%/6);
      }
    }
    .custom-thumbnail-image{
      padding-bottom: 100%;
      flex-shrink: 0;
    }
  }

  // Desktop View
  @media screen and (min-width: 1015px){
    .newsSlideShow-headline{
      right: 0;
      width: 85%;
    }
    .newsSlideShow{
      display: flex;
      flex-flow: row-reverse;
      align-items: stretch;
    }
    .custom-news-slider-header,
    .custom-thumbnail-heading{
      display: block;
    }
    .newsSlideShow-thumbnails{
      width: 26%;
      flex-flow: column;
      justify-content: flex-start;
      padding: 15px;
      box-sizing: content-box;
      border-left: 5px solid $secondary-color;
      li{
        width: 100%;
        + li {
          border-top: 1px solid $gray-md;
        }
      }
    }
    .custom-thumbnail-link{
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
      &:after{
        content: "";
        width: 5px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -20px;
        background-color: transparent;
        transition: background-color $transition-linear;
      }
      &.current{
        &:after{
          background-color: $primary-color;
        }
      }
    }
    .custom-thumbnail-image{
      max-width: 83px;
      padding-bottom: 25.875%;
      margin-right: 15px;
    }

  }
}
