/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// 1. Page Element Styles
.linkElement{
  h4{
    font-family: $fontOne;
    font-size: 12px;
    font-weight: 500;
    .pageEl.center &{text-align: center;}
    .pageEl.right &{text-align: right;}
    a{
      display: inline-block;
      background-color: transparent;
      color: $secondary-color;
      border: 1px solid $secondary-color;
      border-radius: 3px;
      padding: 10px 20px;
      margin-bottom: 0px;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color $transition-linear, color  $transition-linear;
      &:hover{
        background-color: $secondary-color;
        color: #FFF;
      }
      .pageEl.fill &{width: 100%;}
    }
  }
  .goToLink,
  .emailLink{
    &:before{
      display:none;
    }
    &:after{
      color: $primary-color;
      content: "\f105"; // Right Arrow
      font-family: FontAwesome;
      display:inline-block;
      line-height: .8em;
      margin:0 0 0 6px;
      padding:0;
      position: relative;
      transition: color .2s;
    }
  }
  .emailLink {
    &:after{
      content: "\f003"; // envelope-o
    }
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontOne;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color:$link-color;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}
